@use '../utils/mixins.scss' as *;
.customInput {
  .ant-checkbox {
    border-radius: 0.25rem;
    input[type='checkbox'] {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
    .ant-checkbox-inner {
      width: 1.5rem;
      height: 1.5rem;
      background-color: $white;
      border: 0.063rem solid $primary;
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.ant-checkbox-indeterminate .ant-checkbox-inner {
      background-color: $primary !important;
      border-color: $primary !important;
      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 7H13.5' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat no-repeat;
        background-position: center center;
        background-size: cover;
        content: '';
        transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6);
        width: 0.875rem;
        height: 0.875rem;
        display: block;
        position: relative;
        top: 0;
        transform: none;
        inset-inline-start: 0;
        border: 0;
        background-color: $primary;
      }
    }
    &.ant-checkbox-checked .ant-checkbox-inner {
      background-color: $primary;
      border-color: $primary;
      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 8.5498L3.23 12.0598C3.32212 12.1795 3.44016 12.2768 3.57525 12.3444C3.71034 12.4119 3.85898 12.448 4.01 12.4498C4.15859 12.4516 4.3057 12.4201 4.44063 12.3579C4.57555 12.2956 4.6949 12.204 4.79 12.0898L13.5 1.5498' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat no-repeat;
        background-position: center center;
        background-size: cover;
        content: '';
        transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6);
        width: 0.875rem;
        height: 0.875rem;
        display: block;
        position: relative;
        top: 0;
        transform: none;
        inset-inline-start: 0;
        border: 0;
      }
    }
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: $primary;
    border-color: $primary;
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-inner,
  .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: $primary;
  }
  .ant-checkbox + span {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):focus
    .ant-checkbox-inner,
  .ant-checkbox:not(.ant-checkbox-disabled):focus .ant-checkbox-inner {
    border-color: $primary;
    box-shadow:
      0rem 0rem 0rem 0.25rem rgba($white, 1),
      0rem 0rem 0rem 0.375rem rgba($primary, 1);
  }
}
