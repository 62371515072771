@use '../utils/mixins.scss' as *;
.customInput {
  .ant-input,
  textarea.ant-input {
    border: 0.063rem solid $neutral06;
    padding: 1rem 0.75rem;
    font-size: 1rem;
    color: $neutral08;
    background: $transparent;
    font-weight: 400;
    &::placeholder {
      color: $neutral07;
      opacity: 1; /* Firefox */
      font-weight: 400;
      font-size: 1rem;
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: $neutral07;
      font-weight: 400;
      font-size: 1rem;
    }
  }
  .ant-input-textarea-allow-clear > .ant-input,
  .ant-input-textarea-affix-wrapper.ant-input-textarea-has-feedback .ant-input {
    padding-inline-end: 2rem;
  }
  &.ant-form-item .ant-form-item-explain-error {
    color: $error;
  }
  &.ant-form-item .ant-form-item-feedback-icon-error {
    color: $error;
  }
  .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):hover {
    border-color: $error;
    background-color: rgba($error, 0.2) !important;
  }

  .float-input {
    font-size: 1rem;
    color: $neutral07;
    padding: 1rem 0.75rem;
    border: 0.063rem solid $neutral06;
    border-radius: 0.5rem;
    line-height: 1;
    background: $transparent;
    input {
      line-height: 1;
    }

    &:hover {
      border-color: $primary;
    }
    &:focus-within {
      border-color: $primary;
      -webkit-box-shadow: 0rem 0rem 0rem 0.125rem rgba($primary, 0.1);
      -moz-box-shadow: 0rem 0rem 0rem 0.125rem rgba($primary, 0.1);
      box-shadow: 0rem 0rem 0rem 0.125rem rgba($primary, 0.1);
    }
  }
  textarea.float-input {
    padding: 1rem 0.75em;
  }
  .ant-select-single.ant-select-lg {
    height: auto;
  }
  .ant-select-selection-item {
    min-height: auto;
  }
  .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    border-color: $error;
    + .label {
      color: $error;
    }
  }
  .ant-input-outlined:focus,
  .ant-input-outlined:focus-within {
    border-color: $primary;
    -webkit-box-shadow: 0rem 0rem 0rem 0.125rem rgba($primary, 0.1);
    -moz-box-shadow: 0rem 0rem 0rem 0.125rem rgba($primary, 0.1);
    box-shadow: 0rem 0rem 0rem 0.125rem rgba($primary, 0.1);
    background-color: inherit;
  }
  .ant-input-outlined:hover {
    border-color: $primary;
    background-color: inherit;
  }
  &.full-width {
    width: 100%;
    grid-column: span 2;
    @include tab() {
      grid-column: span 1;
    }
  }
}
