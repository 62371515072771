@use '../utils/mixins.scss' as *;
.customInput {
  .ant-radio {
    border-radius: 100%;
    input[type='radio'] {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
    .ant-radio-inner {
      width: 1.5rem;
      height: 1.5rem;
      background-color: $white;
      border: 0.063rem solid $primary;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      &::after {
        width: 0rem;
        height: 0rem;
        margin-block-start: 0;
        margin-inline-start: 0;
        position: unset;
        flex: 0 0 auto;
        box-sizing: border-box;
        display: flex;
        transform: none;
        opacity: 0;
        transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
        content: '';
        margin: auto;
      }
    }
    &.ant-radio-indeterminate .ant-radio-inner {
      background-color: $primary !important;
      border-color: $primary !important;
    }
    &.ant-radio-checked .ant-radio-inner {
      background-color: $primary;
      border-color: $primary;
      &::after {
        transform: none;
        width: 0.5rem;
        height: 0.5rem;
        opacity: 1;
        transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      }
    }
  }
  .ant-radio-wrapper:not(.ant-radio-wrapper-disabled):hover
    .ant-radio-checked:not(.ant-radio-disabled)
    .ant-radio-inner {
    background-color: $primary;
    border-color: $primary;
  }
  .ant-radio-wrapper:not(.ant-radio-wrapper-disabled):hover .ant-radio-inner,
  .ant-radio:not(.ant-radio-disabled):hover .ant-radio-inner {
    border-color: $primary;
  }
  .ant-radio-wrapper {
    margin-inline-end: 0rem;
  }
  .ant-radio-wrapper span.ant-radio + * {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }
  .ant-radio-wrapper:not(.ant-radio-wrapper-disabled):focus .ant-radio-inner,
  .ant-radio:not(.ant-radio-disabled):focus .ant-radio-inner {
    border-color: $primary;
    box-shadow:
      0rem 0rem 0rem 0.25rem rgba($white, 1),
      0rem 0rem 0rem 0.375rem rgba($primary, 1);
  }
}
