@font-face {
  font-family: "Fakt Pro";
  src: url("../../public/fonts/FaktPro-NormalItalic.eot");
  src: local("Fakt Pro Normal Italic"), local("FaktPro-NormalItalic"),
    url("../../public/fonts/FaktPro-NormalItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/FaktPro-NormalItalic.woff2") format("woff2"),
    url("../../public/fonts/FaktPro-NormalItalic.woff") format("woff"),
    url("../../public/fonts/FaktPro-NormalItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Fakt Pro";
  src: url("../../public/fonts/FaktPro-Light.eot");
  src: local("Fakt Pro Light"), local("FaktPro-Light"),
    url("../../public/fonts/FaktPro-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/FaktPro-Light.woff2") format("woff2"),
    url("../../public/fonts/FaktPro-Light.woff") format("woff"),
    url("../../public/fonts/FaktPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Fakt Pro Blond";
  src: url("../../public/fonts/FaktPro-Blond.eot");
  src: local("Fakt Pro Blond"), local("FaktPro-Blond"),
    url("../../public/fonts/FaktPro-Blond.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/FaktPro-Blond.woff2") format("woff2"),
    url("../../public/fonts/FaktPro-Blond.woff") format("woff"),
    url("../../public/fonts/FaktPro-Blond.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Fakt Pro";
  src: url("../../public/fonts/FaktPro-BoldItalic.eot");
  src: local("Fakt Pro Bold Italic"), local("FaktPro-BoldItalic"),
    url("../../public/fonts/FaktPro-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/FaktPro-BoldItalic.woff2") format("woff2"),
    url("../../public/fonts/FaktPro-BoldItalic.woff") format("woff"),
    url("../../public/fonts/FaktPro-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Fakt Pro";
  src: url("../../public/fonts/FaktPro-MediumItalic.eot");
  src: local("Fakt Pro Medium Italic"), local("FaktPro-MediumItalic"),
    url("../../public/fonts/FaktPro-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/FaktPro-MediumItalic.woff2") format("woff2"),
    url("../../public/fonts/FaktPro-MediumItalic.woff") format("woff"),
    url("../../public/fonts/FaktPro-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Fakt Pro";
  src: url("../../public/fonts/FaktPro-Bold.eot");
  src: local("Fakt Pro Bold"), local("FaktPro-Bold"),
    url("../../public/fonts/FaktPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/FaktPro-Bold.woff2") format("woff2"),
    url("../../public/fonts/FaktPro-Bold.woff") format("woff"),
    url("../../public/fonts/FaktPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Fakt Pro";
  src: url("../../public/fonts/FaktPro-Normal.eot");
  src: local("Fakt Pro Normal"), local("FaktPro-Normal"),
    url("../../public/fonts/FaktPro-Normal.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/FaktPro-Normal.woff2") format("woff2"),
    url("../../public/fonts/FaktPro-Normal.woff") format("woff"),
    url("../../public/fonts/FaktPro-Normal.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Fakt Pro";
  src: url("../../public/fonts/FaktPro-Medium.eot");
  src: local("Fakt Pro Medium"), local("FaktPro-Medium"),
    url("../../public/fonts/FaktPro-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/FaktPro-Medium.woff2") format("woff2"),
    url("../../public/fonts/FaktPro-Medium.woff") format("woff"),
    url("../../public/fonts/FaktPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
