@use './utils/mixins.scss' as *;
@forward './utils/vars.scss';
@import './utils/fonts.scss';
@import './components/breadcrumb.scss';
@import './components/checkbox.scss';
@import './components/drawer.scss';
@import './components/input.scss';
@import './components/select.scss';
@import './components/popover.scss';
@import './components/radio.scss';

::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: $secondary;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primaryAlt;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Fakt Pro', sans-serif;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  max-width: 100vw;
  font-size: calc(0.625rem + 0.3dvw);
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include des() {
    font-size: 1rem;
  }
}

body {
  font-family: 'Fakt Pro', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  background-color: $neutral01;
  accent-color: $primary;
  letter-spacing: 0.002rem;
  padding-top: 11rem;

  @include tab() {
    padding-top: 5.25rem;
  }
  .ant-form {
    font-family: 'Fakt Pro', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1rem;
    line-height: normal;
  }
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 3.75rem;

  @include tab() {
    padding: 0 1.875rem;
  }
}

.mySolutionContainer {
  width: 100%;
  margin: 0 auto;
  padding: 0 3.75rem;

  @include mob() {
    padding: 0;
  }
}

.pageSection {
  margin: 15.25rem 0;

  @include tab() {
    margin: 12.5rem 0;
  }
  &Small {
    margin: 4rem 0;
  }
}

.findSolutionPageSection {
  margin-top: 6.75rem;

  @include mob() {
    margin: 5.12rem 0;
  }
}

.text-center {
  text-align: center;
}

.text-nowrap {
  white-space: nowrap !important;
}

.headingElement {
  position: relative;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Fakt Pro', sans-serif;
    margin-bottom: -0.5rem;
    font-weight: 500;

    &.titleXXXXXl {
      font-size: 15rem;

      @include tab-des() {
        font-size: 5.125rem;
      }
    }

    &.titleXXXXl {
      font-size: 10rem;

      @include tab-des() {
        font-size: 4.125rem;
      }
    }

    &.titleXXXl {
      font-size: 6rem;
      letter-spacing: 0.06rem;

      @include tab-des() {
        font-size: 2rem;
      }
    }

    &.titleXXl {
      font-size: 3.75rem;
      letter-spacing: -0.019rem;
      @include tab-des() {
        font-size: 2rem;
      }
    }

    &.titleXl {
      font-size: 2.5rem;

      @include tab-des() {
        font-size: 1.5rem;
      }
    }

    &.titleLgXl {
      font-size: 3.75rem;

      @include tab-des() {
        font-size: 1.5rem;
      }
    }

    &.titleLg {
      font-size: 2.125rem;
      letter-spacing: 0.005rem;

      @include tab-des() {
        font-size: 1.5rem;
      }
    }

    &.titleMd {
      font-size: 1.5rem;

      @include tab-des() {
        font-size: 1.5rem;
      }
    }

    &.titleSm {
      font-size: 1.25rem;
      letter-spacing: 0.06rem;
    }

    &.subTitle {
      font-size: 1rem;
      color: $primary;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      letter-spacing: 0.002rem;
    }

    &.titleCaption {
      font-size: 0.875rem;
      color: $primary;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0.75rem;
      letter-spacing: 0.018rem;
    }

    &.light {
      font-weight: 300;
    }

    &.normal {
      font-weight: 400;
    }

    &.medium {
      font-weight: 500;
    }

    &.semiBold {
      font-weight: 600;
    }

    &.strong {
      font-weight: bold;
    }

    &.extraStrong {
      font-weight: 700;
    }
  }
}

.smallSubTitle {
  font-size: 1rem;
}

.mediumSubTitle {
  font-size: 3rem;
}

.base-font {
  font-size: 1rem;
}

.body-sm {
  font-size: 0.875rem;
}

.light {
  font-weight: 300;
}

.normal {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semiBold {
  font-weight: 600;
}

.strong {
  font-weight: bold;
}

.extraStrong {
  font-weight: 700;
}

.text-underline {
  text-decoration: underline;
}

.pos-relative {
  position: relative;
}

.swiper-wrapper {
  display: flex;
  align-items: flex-start;
  transition-timing-function: linear !important;
  position: relative;
  user-select: none;
  -webkit-touch-callout: none;
  backface-visibility: hidden;
}

.full-width {
  width: 100%;
}

.editorDescription {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1.5rem;
    font-weight: 500;
    &:not(:first-child) {
      margin-top: 2.5rem;
    }
  }
  h1 {
    font-size: 6rem;
  }
  h2 {
    font-size: 4.5rem;
  }
  h3 {
    font-size: 3rem;
  }
  h4 {
    font-size: 2.125rem;
  }
  h5 {
    font-size: 1.5rem;
    @include mob() {
      font-size: 1rem;
    }
  }
  h6 {
    font-size: 1.5rem;
    @include mob() {
      font-size: 1rem;
    }
  }

  p {
    margin-bottom: 1rem;
  }
  ul,
  ol {
    padding-left: 1.5rem;
    margin-bottom: 1rem;
  }
  a {
    color: $primary;
    &:hover,
    &:focus,
    &:active {
      color: $primaryAlt;
    }
  }
}
