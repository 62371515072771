@use '../utils/mixins.scss' as *;
.customInput {
  .ant-select .ant-select-selection-placeholder {
    color: $neutral07;
    opacity: 1;
    font-weight: 400;
    font-size: 1rem;
  }
  .ant-form-item-control-input {
    min-height: auto !important;
  }
  .ant-select.ant-select-lg.ant-select-outlined {
    .ant-select-selector {
      border: 0.063rem solid $neutral06;
      padding: 1rem 0.75rem;
      font-size: 1rem;
      color: $neutral08;
      background: $transparent;
      font-weight: 400;
      border-radius: 0.5rem;

      .ant-select-selection-search-input {
        height: 100%;
      }

      &:focus-within {
        border-color: $primary !important;
        -webkit-box-shadow: 0rem 0rem 0rem 0.125rem rgba($primary, 0.1) !important;
        -moz-box-shadow: 0rem 0rem 0rem 0.125rem rgba($primary, 0.1) !important;
        box-shadow: 0rem 0rem 0rem 0.125rem rgba($primary, 0.1) !important;
      }
    }
    &:hover {
      .ant-select-selector {
        border-color: $primary !important;
        box-shadow: none !important;
      }
    }
  }
  &.ant-form-item .ant-form-item-explain-error {
    color: $error;
  }
  &.ant-form-item .ant-form-item-feedback-icon-error {
    color: $error;
  }
  .ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: $error;
  }
  .ant-select-outlined.ant-select-status-error:not(.ant-select-disabled):hover {
    border-color: $error;
    background-color: rgba($error, 0.2) !important;
  }
  .ant-select-outlined.ant-select-status-error:not(.ant-select-disabled) {
    border-color: $error;
    + .label {
      color: $error;
    }
  }
}
